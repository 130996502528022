import Vue from 'vue'
import App from './App.vue'
import './assets/font/iconfont.css'
import './assets/font/iconfont.js'
import store from './store'
import common from './utils/common'

import VMdPreview from '@kangc/v-md-editor/lib/preview';
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';
import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index';
VMdPreview.use(createLineNumbertPlugin());

import "../src/icons";
import { vueBaberrage } from 'vue-baberrage'
Vue.use(vueBaberrage)

import jsCookie from 'js-cookie'

import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(VueViewer);

import element from '@/element/index'
Vue.use(element)

import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';

import Toast from '@/components/toast/index.vue';

const ToastPlugin = {
  install(Vue) {
    Vue.prototype.$toast = new Vue(Toast).$mount();
    document.body.appendChild(Vue.prototype.$toast.$el);
  },
};

Vue.use(ToastPlugin);

import hljs from 'highlight.js';

import 'highlight.js/styles/atom-one-dark-reasonable.css' //样式
//创建v-highlight全局指令
Vue.directive('highlight', function (el) {
  let blocks = el.querySelectorAll('pre code');
  blocks.forEach((block) => {
    hljs.highlightBlock(block)
  })
})

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import Prism from 'prismjs';
import Clipboard from 'clipboard'
import Loading from '@/components/loading/loading';
import VueLazyLoad from 'vue-lazyload'

// 注册全局组件
Vue.component('loading', Loading);

// 创建一个 Vue 实例作为事件总线
Vue.prototype.$bus = new Vue();
Vue.prototype.Clipboard = Clipboard
Vue.prototype.$common = common

import MetaInfo from 'vue-meta-info';


VueMarkdownEditor.use(vuepressTheme, {
  Prism,
});
Vue.use(VueMarkdownEditor);
VMdPreview.use(vuepressTheme, {
  Prism
});
VMdPreview.use(createCopyCodePlugin())
Vue.use(VMdPreview);

Vue.use(MetaInfo)
Vue.use(mavonEditor)
Vue.prototype.$cookie = jsCookie;  // 在页面里可直接用 this.$cookie 调用
Vue.use(vueBaberrage)

Vue.use(VueLazyLoad, {
  preLoad: 1,
  error: require('./assets/image/error.gif'),
  loading: require('./assets/image/loading-2.gif'),
  attempt: 2,
})

import Empty from '@/components/empty/index.vue'
Vue.component("sy-empty", Empty);
import pagination from '@/components/pagination/index.vue'
Vue.component("sy-pagination", pagination);
import router from './router'

window.vm = new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
router.afterEach(() => {
  window.scrollTo({
    top: 0,
    behavior: "instant"
  });
});


